<template>
  <div class="reward-details-component">
    <div>
      <div class="reward-header">
        <img v-if="isFund" class="h-20 mx-auto" src="/img/pages/fund.png" />
        <img v-else class="h-20 mx-auto" src="/img/pages/reward.png" />
      </div>

      <div class="reward-details">
        <EssentialsHelpButton />
        <div class="headline">
          <p class="title">
            {{ reward.title }}
          </p>

          <div class="text" v-html="reward.description" />

          <RewardsSponsorLink :reward="reward" />
        </div>

        <div class="detail">
          <p class="detail-title">{{ $t('components.rewards.details') }}</p>
          <div class="detail-row">
            <p class="detail-col-title">
              {{ $t('components.rewards.category') }}
            </p>
            <p class="detail-col-value">
              {{ categories }}
            </p>
          </div>
          <div
            v-if="reward.delivery_type !== 'external' && !isFund"
            class="detail-row"
          >
            <p class="detail-col-title">
              {{ $t('components.rewards.delivery') }}
            </p>
            <p class="detail-col-value">
              {{ deliveryType }}
            </p>
          </div>
        </div>

        <div v-if="!isFund" class="redeem-info">
          <div class="detail-row">
            <p class="detail-title">
              {{
                $t('components.rewards.payment_types.' + reward.payment_type)
              }}
            </p>
          </div>
          <div class="detail-row">
            <p class="detail-col-title">
              {{ $t('components.rewards.cost') }}
            </p>
            <p class="detail-col-value">
              {{ price }}
              <img :src="currencyIcon" />
            </p>
          </div>

          <div
            v-if="
              reward.redeemable_frequency !== 'unlimited' &&
              reward.redeemable_frequency !== ''
            "
            class="detail-row"
          >
            <p class="detail-col-title">
              {{ $t('components.rewards.limit') }}
            </p>
            <div class="detail-col-value">
              <p>
                {{
                  $t(`components.rewards.limits.${reward.redeemable_frequency}`)
                }}
              </p>
            </div>
          </div>
          <div v-else class="detail-row">
            <p class="detail-col-title">
              {{ $t('components.rewards.limit') }}
            </p>
            <div class="detail-col-value">
              <p v-if="reward.redeemable_count === 0">-</p>
              <p v-else>
                {{ reward.redeemable_count }}
              </p>
            </div>
          </div>
        </div>

        <RewardsFundDetailProgress
          v-else
          class="mt-6"
          :value="fund"
          :svg="reward.fund_svg"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'pinia';

export default {
  props: {
    reward: {
      type: Object,
      required: true,
    },
  },

  computed: {
    categories() {
      return this.reward.categories.map((c) => c.title).join(', ');
    },

    deliveryType() {
      if (this.reward.payment_type === 'fund') {
        return '-';
      }

      return this.$t(
        'components.rewards.delivery_type.' + this.reward.delivery_type
      );
    },

    price() {
      if (this.reward.payment_type === 'fund') {
        return '-';
      }

      return this.reward.price.toLocaleString('de-DE');
    },

    isFund() {
      return this.reward.payment_type === 'fund';
    },

    fund() {
      return typeof this.reward.fund === 'number' ? this.reward.fund : 0;
    },

    ...mapState(useMeStore, ['currencyIcon']),
  },
};
</script>

<style lang="scss" scoped>
.reward-details-component {
  @apply pb-4 -mt-8;
}

.headline {
  @apply text-center w-full py-4;

  img {
    @apply h-10 inline;
  }
}

.title {
  @apply font-theme-title text-2xl tracking-medium uppercase leading-xs text-theme-secondary;
}

.text {
  @apply block mt-4 text-left leading-tight;
}

.reward-details {
  @apply bg-white text-theme-primary px-8 relative -mt-12 pt-16 pb-8 shadow-detail rounded-theme-large;
}

.reward-image {
  @apply w-full h-48 overflow-hidden relative text-center;
}

.reward-header {
  @apply z-10 relative;
}

.detail-title {
  @apply text-theme-secondary font-theme-title text-2xl uppercase w-1/3;
}

.detail-row {
  @apply flex py-1;
}

.detail-col-title {
  @apply w-1/3;
}

.detail-col-value {
  @apply w-2/3 text-right;

  img {
    @apply h-8 inline;
  }
}

.redeem-info {
  @apply mt-8;
}
</style>
